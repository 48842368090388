import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import './Style.scss'
import DesktopHome from './assets/desktop-img.svg'
import Special from './assets/special.svg'
import {ReactComponent as PlingotLogo} from './assets/plingot-logo.svg'
import {ReactComponent as Hamburger} from './assets/hamburger.svg'
import {ReactComponent as Cross} from './assets/cross.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navExpanded: false
    }

    this.setNavExpanded = this.setNavExpanded.bind(this)
  }

  setNavExpanded(expanded) {
    if (expanded === true) {
      document.body.style.overflowY = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = '0'
      document.body.style.bottom = '0'
      document.body.style.right = '0'
      document.body.style.left = '0'
    } else {
      document.body.style.overflowY = null
      document.body.style.position = null
      document.body.style.top = null
      document.body.style.bottom = null
      document.body.style.right = null
      document.body.style.left = null
    }
    this.setState({ navExpanded: expanded })
  }

  render() {
    return (
      <div className="Home">
        <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} expand="xl">
          <div className="container">
            <a className="navbar-brand" href="/">.scubasteve</a>
            {this.state.navExpanded === true ? (
              <>
                <Navbar.Toggle aria-controls="main-navbar-nav"><Cross /></Navbar.Toggle>
              </>
            ) : (
              <>
                <Navbar.Toggle aria-controls="main-navbar-nav"><Hamburger /></Navbar.Toggle>
              </>
            )}
            <Navbar.Collapse id="main-navbar-nav">
              <ul className="navbar-nav mr-auto ml-auto">
                <li className="nav-item active">
                  <AnchorLink onClick={() => this.setNavExpanded(false)} href='#prices' className="nav-link">Priser</AnchorLink>
                </li>
                <li className="nav-item">
                  <span>.</span>
                </li>
                <li className="nav-item active">
                  <a onClick={() => this.setNavExpanded(false)} href="https://try.scubasteve.se" target="_blank" rel="noopener noreferrer" className="nav-link">Testa</a>
                </li>
                <li className="nav-item">
                  <span>.</span>
                </li>
                <li className="nav-item">
                  <AnchorLink onClick={() => this.setNavExpanded(false)} href='#about' className="nav-link">Om oss</AnchorLink>
                </li>
                <li className="nav-item">
                  <span>.</span>
                </li>
                <li className="nav-item">
                  <AnchorLink onClick={() => this.setNavExpanded(false)} href='#footer' className="nav-link">Kontakt</AnchorLink>
                </li>
              </ul>
              <div className="mobile-flex-box">
                {/* <AnchorLink className="nav-link">Registrera</AnchorLink> */}
                <a href="mailto:hello@scubasteve.se?subject=Jag vill veta mer om ScubaSteve" target="_blank" rel="noopener noreferrer" className="nav-link contactMe">Jag vill bli kontaktad</a>
                <a href="https://app.scubasteve.se"><button className="btn portal" type="submit">Logga in</button></a>
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <div className="section-one">
          <div className="container">
            <section id="one">
              <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                  <div className="d-flex flex-column justify-content-center">
                    <div className="p-0"><p className="pretitle">— För dykcentrum i framkant.</p></div>
                    <div className="p-0"><h1 className="title">Vi hanterar dina evenemang.</h1></div>
                    <div className="p-0"><p className="subtitle">ScubaSteve är en plattform för eventbokningar där målet är<br/>att förenkla hanteringen av era evenemang.</p></div>
                    <div className="p-0"><p className="showmore"><a href="https://try.scubasteve.se" target="_blank" rel="noopener noreferrer">Testa nu</a></p></div>
                    <div className="p-0">
                      <img src={Special} alt="Special" className="special-img" />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2 oskarp">
                  <div className="d-flex flex-column justify-content-center">
                    <div className="p-0">
                      <img src={DesktopHome} alt="Desktop Home" className="desktop-home-img" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section id="prices">
          <div className="container">
            <h1>Priser</h1>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="price-table one">
                  <h4>Entanks-lösning</h4>
                  <h2>Kontakta oss</h2>
                  <p>
              - Admin-portal.
                    <br />
              - Sida med subdomän hos oss.
                    <br />
              - Användare & registration.
                    <br />
              - Obegränsat med anmälningar.
                    <br />
              - 1000 utfärder per år.
                    <br />
              - Egna logotyper
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="price-table two">
                  <h4>Tvåtanks-lösning</h4>
                  <h2>Kontakta oss</h2>
                  <p>
              - Allt från entanks-lösningen.
                    <br />
              - Egen domän.
                    <br />
              - Integration till google.
                    <br />
              - 1500 utfärder per år.
                    <br />
              - Swish betalning.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="price-table three">
                  <h4>Rebreather-lösning</h4>
                  <h2>Kontakta oss</h2>
                  <p>
                - Allt från tvåtanks-lösningen.
                    <br />
                - Integrationer till era val.
                    <br />
                - Över 1500 utfärder per år.
                    <br />
                - Swish betalning för utfärder.
                    <br />
                - Prioriterad support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about">
          <div className="container">
            <h1>Om oss</h1>
            <div className="row">
              <div className="col-12 col-lg-7">
                <p>
              Huvudet bakom ScubaSteve är Plingot AB.
                  <br />
              Vårt mål med tjänsten är att underlätta för dykcentrum när det gäller eventbokningar. Innan vi lanserade ScubaSteve upplevde flera av våra nuvarande kunder att kontakten mellan dem och deras dykare var svår och att en eventboknings-plattform hade underlättat i deras företagande.
                  <br />
              Plingot är ett team av utvecklare med lång och bred erfarenhet. Komplicerade problem är det som får upp oss på morgonen - vi brinner för utmaningen och är inte rädda för att konstant höja ribban.
                </p>
              </div>
              <div className="col-12 col-lg-5">
                <div className="d-flex justify-content-center">
                  <div className="p-0">
                    <PlingotLogo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="footer">
          <div className="container">
            <h1>Kontakt</h1>
            <div className="row">
              <div className="col-12 col-lg-2">
                <p>
                  Plingot AB
                  <br />
                  Orgnr. 559103-4508
                  <br />
                  © {new Date().getFullYear()} Copyright
                </p>
              </div>
              <div className="col-12 col-lg-2">
                <p>
                  E-post:
                  <br />
                  <a href="mailto:scubasteve@plingot.com">hello@scubasteve.se</a>
                </p>
              </div>
              <div className="col-12 col-lg-2">
                <p>
                  Telefon:
                  <br />
                  <a href="tel:0470556910">0470 - 55 69 10</a>
                </p>
              </div>
              <div className="col-12 col-lg-6 brand-footer">
                <p>
                  .scubasteve
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Home
